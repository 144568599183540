var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.mostrarBotaoFlutuante && _vm.selecionados.length !== 0
        ? _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function({ on }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              attrs: {
                                fab: "",
                                dark: "",
                                color: "green",
                                fixed: "",
                                right: "",
                                bottom: ""
                              },
                              on: { click: _vm.dialogTemCerteza }
                            },
                            on
                          ),
                          [
                            _c("v-icon", { attrs: { dark: "" } }, [
                              _vm._v("check")
                            ])
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                350095688
              )
            },
            [
              _c("span", [
                _vm._v("Solicitar " + _vm._s(_vm.type) + " para Selecionados")
              ])
            ]
          )
        : _vm._e(),
      _c(
        "v-card",
        { staticClass: "mt-3" },
        [
          _c(
            "v-row",
            { staticClass: "ma-0" },
            [
              _c("v-spacer"),
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-end pr-9",
                  attrs: { sm: "6", md: "3", lg: "4" }
                },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: {
                        top: "",
                        disabled: _vm.selecionados.length !== 0
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function({ on }) {
                            return [
                              _c(
                                "div",
                                _vm._g({}, on),
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      directives: [
                                        {
                                          name: "intersect",
                                          rawName: "v-intersect",
                                          value: _vm.onIntersect,
                                          expression: "onIntersect"
                                        }
                                      ],
                                      staticClass: "d-flex justify-center",
                                      attrs: {
                                        color: "green white--text",
                                        disabled: !_vm.selecionados.length
                                      },
                                      on: { click: _vm.dialogTemCerteza }
                                    },
                                    [
                                      _vm._v(
                                        " Solicitar " +
                                          _vm._s(_vm.type) +
                                          " para Selecionados "
                                      ),
                                      _c("v-icon", { staticClass: "ml-2" }, [
                                        _vm._v("mdi-chevron-right")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "Selecione um ou mais títulos para liberar o comando"
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.temCerteza
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "800px",
                persistent: "",
                scrollable: "",
                color: "background",
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.temCerteza,
                callback: function($$v) {
                  _vm.temCerteza = $$v
                },
                expression: "temCerteza"
              }
            },
            [
              _c(
                "v-card",
                { staticClass: "confirmation-dialog" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "", dark: "", color: "primary" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", dark: "" },
                          on: { click: _vm.closeDialog }
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                      _vm.titulosEnviadosCheck
                        ? _c("v-toolbar-title", [_vm._v(" Tem certeza? ")])
                        : _vm._e(),
                      _vm.resultadoCheck
                        ? _c("v-toolbar-title", [_vm._v(" Envio Finalizado! ")])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "mb-3" }),
                  _vm.loadingConfirmacao
                    ? _c(
                        "div",
                        { staticClass: "block-loading" },
                        [
                          _c("v-progress-circular", {
                            attrs: {
                              size: 50,
                              color: "primary",
                              indeterminate: ""
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.titulosEnviadosCheck
                    ? _c(
                        "v-card-text",
                        {
                          staticStyle: { "border-radius": "0" },
                          attrs: { "min-height": "200px" }
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-subheader",
                                    {
                                      staticClass:
                                        "primary--text title font-weight-black"
                                    },
                                    [
                                      _vm._v(
                                        "Número de Títulos selecionados: " +
                                          _vm._s(_vm.selecionados.length) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "d-flex justify-end" },
                                [
                                  (_vm.isSuperAdminRecuperi &&
                                    _vm.type === "Cancelamento") ||
                                  _vm.type === "Desistência"
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "primary white--text d-flex justify-center mt-3",
                                          attrs: { medium: "" },
                                          model: {
                                            value: _vm.botaoRelatorioXls,
                                            callback: function($$v) {
                                              _vm.botaoRelatorioXls = $$v
                                            },
                                            expression: "botaoRelatorioXls"
                                          }
                                        },
                                        [
                                          _c(
                                            "download-excel",
                                            {
                                              staticClass: "ma-auto",
                                              attrs: {
                                                data: _vm.relatorioValores,
                                                fields: _vm.headersCSV,
                                                name:
                                                  "Resultado-" +
                                                  _vm.type +
                                                  ".xls",
                                                worksheet: "resultados",
                                                type: "xls"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { staticClass: "ma-3" },
                                                [_vm._v("mdi-microsoft-excel")]
                                              ),
                                              _vm._v(
                                                "Relatorio " +
                                                  _vm._s(_vm.type) +
                                                  " "
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-subheader", [
                            _vm._v(
                              "Os seguintes títulos terão " +
                                _vm._s(_vm.type) +
                                " Solicitada:"
                            )
                          ]),
                          _c("v-simple-table", [
                            _c("thead", [
                              _c("tr", [
                                _c("th", { staticClass: "text-left" }, [
                                  _vm._v("Título")
                                ])
                              ])
                            ]),
                            _c(
                              "tbody",
                              _vm._l(_vm.selecionados, function(item, i) {
                                return _c("tr", { key: i }, [
                                  _c("td", [
                                    _vm._v(_vm._s(item.ds_nosso_numero))
                                  ])
                                ])
                              }),
                              0
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.resultadoCheck
                    ? _c(
                        "v-card-text",
                        {
                          staticClass: "mx-auto",
                          staticStyle: { "border-radius": "0px" },
                          attrs: { "min-height": "200px" }
                        },
                        [
                          _c(
                            "v-list",
                            { staticClass: "mx-auto" },
                            [
                              _c("v-subheader", [
                                _vm._v(
                                  "Confira abaixo se todos os títulos tiveram sua " +
                                    _vm._s(_vm.type) +
                                    " solicitada:"
                                )
                              ]),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "max-height": "400px",
                                    "overflow-y": "auto"
                                  }
                                },
                                [
                                  _c("v-data-table", {
                                    attrs: {
                                      scrollable: "",
                                      headers: _vm.headers,
                                      items: _vm.avisoRetorno,
                                      "items-per-page": 900,
                                      "item-key": "id",
                                      "hide-default-footer": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item",
                                          fn: function({ item }) {
                                            return [
                                              _c("tr", [
                                                _c(
                                                  "td",
                                                  [
                                                    item.retorno.codigo === 200
                                                      ? _c(
                                                          "v-icon",
                                                          {
                                                            staticClass:
                                                              "success--text"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " mdi-check-circle "
                                                            )
                                                          ]
                                                        )
                                                      : item.retorno.codigo ===
                                                        400
                                                      ? _c(
                                                          "v-icon",
                                                          {
                                                            staticClass:
                                                              "error--text"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " mdi-close-circle "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                ),
                                                _c("td", [
                                                  _vm._v(_vm._s(item.titulo))
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(item.id))
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.retorno.mensagem
                                                    )
                                                  )
                                                ])
                                              ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2663028015
                                    )
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                {
                                  staticClass:
                                    "ma-auto text-center font-weight-black"
                                },
                                [_vm._v("Iniciando download dos resultados...")]
                              ),
                              _c(
                                "v-card-actions",
                                { staticClass: "justify-center" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "grey white--text d-flex justify-center",
                                      attrs: { medium: "" },
                                      model: {
                                        value: _vm.botaoRelatorioXls,
                                        callback: function($$v) {
                                          _vm.botaoRelatorioXls = $$v
                                        },
                                        expression: "botaoRelatorioXls"
                                      }
                                    },
                                    [
                                      _c(
                                        "download-excel",
                                        {
                                          staticClass: "ma-auto",
                                          attrs: {
                                            data: _vm.avisoRetorno,
                                            fields: _vm.json_fields,
                                            name: "Resultado.xls",
                                            worksheet: "resultados",
                                            type: "xls"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "ma-3" },
                                            [_vm._v("mdi-microsoft-excel")]
                                          ),
                                          _vm._v("Baixar XLS Resultados ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-divider"),
                  _vm.titulosEnviadosCheck
                    ? _c(
                        "v-card-actions",
                        { staticClass: "justify-end" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-2",
                              attrs: {
                                color: "success",
                                loading: _vm.loadingFiltros
                              },
                              on: { click: _vm.confirmarEnvio }
                            },
                            [
                              _c("v-icon", [_vm._v(" mdi-chevron-right")]),
                              _vm._v(" Seguir com " + _vm._s(_vm.type))
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-2",
                              on: { click: _vm.closeDialog }
                            },
                            [_vm._v("Cancelar")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.confirmAgrupador
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "800px",
                persistent: "",
                scrollable: "",
                color: "background",
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.confirmAgrupador,
                callback: function($$v) {
                  _vm.confirmAgrupador = $$v
                },
                expression: "confirmAgrupador"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "", dark: "", color: "primary" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", dark: "" },
                          on: { click: _vm.closeDialog }
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                      _vm.titulosEnviadosCheck
                        ? _c("v-toolbar-title", [
                            _vm._v(" Solicitação de Alteração de Agrupador ")
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _vm.titulosEnviadosCheck
                    ? _c(
                        "v-card-text",
                        {
                          staticStyle: { "border-radius": "0" },
                          attrs: { "min-height": "200px" }
                        },
                        [
                          _c(
                            "v-list",
                            { attrs: { shaped: "" } },
                            [
                              _c(
                                "v-subheader",
                                {
                                  staticClass:
                                    "primary--text title font-weight-black"
                                },
                                [
                                  _vm._v(
                                    "Número de Títulos selecionados: " +
                                      _vm._s(_vm.selecionados.length) +
                                      " "
                                  )
                                ]
                              ),
                              _c("v-subheader", [
                                _vm._v(
                                  ' Os Títulos abaixo terão a mudança de Agrupador solicitada. Utilize o campo abaixo para definir o novo agrupador a partir da listagem de Agrupadores disponíveis. Importante: ao selecionar "Nenhum Agrupador", todos os Títulos selecionados terão seu agrupador removido. '
                                )
                              ]),
                              _c(
                                "v-row",
                                { staticClass: "mx-0 mb-0 mt-3" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "4",
                                        xs: "12",
                                        sm: "12",
                                        md: "6",
                                        lg: "6"
                                      }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          outlined: "",
                                          dense: "",
                                          color: "success",
                                          disabled: _vm.loadingAgrupador,
                                          loading: _vm.loadingAgrupador,
                                          items: _vm.agrupadores,
                                          label: "Novo Agrupador",
                                          required: "",
                                          "item-text": "nm_agrupador",
                                          "item-value": "ds_agrupador",
                                          "data-cy": "novoAgrupador",
                                          "hide-details": ""
                                        },
                                        model: {
                                          value: _vm.novoAgrupador,
                                          callback: function($$v) {
                                            _vm.novoAgrupador = $$v
                                          },
                                          expression: "novoAgrupador"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "ma-0" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "font-weight-bold",
                                      attrs: {
                                        cols: "4",
                                        xs: "6",
                                        sm: "6",
                                        md: "6",
                                        lg: "6"
                                      }
                                    },
                                    [_vm._v(" Título ")]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "font-weight-bold",
                                      attrs: {
                                        cols: "4",
                                        xs: "6",
                                        sm: "6",
                                        md: "6",
                                        lg: "6"
                                      }
                                    },
                                    [_vm._v(" Agrupador atual ")]
                                  )
                                ],
                                1
                              ),
                              _vm._l(_vm.selecionados, function(item, i) {
                                return _c(
                                  "v-list-item",
                                  {
                                    key: i,
                                    staticClass: "px-0",
                                    attrs: { shaped: "" }
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      { staticClass: "ma-0" },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "pa-0",
                                            attrs: {
                                              cols: "4",
                                              xs: "6",
                                              sm: "6",
                                              md: "6",
                                              lg: "6"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-card-text",
                                              { staticClass: "bold py-3" },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { staticClass: "ma-1" },
                                                  [_vm._v("mdi-information")]
                                                ),
                                                _vm._v(
                                                  "Título: " +
                                                    _vm._s(
                                                      item.ds_nosso_numero
                                                    ) +
                                                    " "
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "pa-0",
                                            attrs: {
                                              cols: "4",
                                              xs: "6",
                                              sm: "6",
                                              md: "6",
                                              lg: "6"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-card-text",
                                              { staticClass: "bold py-3" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.ds_agrupador
                                                        ? item.ds_agrupador
                                                        : "--"
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-divider"),
                  _vm.titulosEnviadosCheck
                    ? _c(
                        "v-card-actions",
                        { staticClass: "justify-end" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-2",
                              attrs: {
                                color: "success",
                                loading: _vm.loadingFiltros
                              },
                              on: { click: _vm.alteracaoAgrupadorMassa }
                            },
                            [
                              _c("v-icon", [_vm._v(" mdi-chevron-right")]),
                              _vm._v(" Solicitar")
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-2",
                              attrs: { loading: _vm.loadingFiltros },
                              on: { click: _vm.closeDialog }
                            },
                            [_vm._v("Cancelar")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }