<template>
    <div>
        <v-tooltip
            v-if="mostrarBotaoFlutuante && selecionados.length !== 0"
            top
        >
            <template v-slot:activator="{ on }">
                <v-btn
                    v-on="on"
                    fab
                    dark
                    color="green"
                    fixed
                    right
                    bottom
                    @click="dialogTemCerteza"
                >
                    <v-icon dark>check</v-icon>
                </v-btn>
            </template>
            <span>Solicitar {{ type }} para Selecionados</span>
        </v-tooltip>
        <v-card class="mt-3">
            <v-row class="ma-0">
                <v-spacer></v-spacer>
                <v-col sm="6" md="3" lg="4" class="d-flex justify-end pr-9">
                    <v-tooltip top :disabled="selecionados.length !== 0">
                        <template v-slot:activator="{ on }">
                            <div v-on="on">
                                <v-btn
                                    class="d-flex justify-center"
                                    color="green white--text"
                                    @click="dialogTemCerteza"
                                    :disabled="!selecionados.length"
                                    v-intersect="onIntersect"
                                >
                                    Solicitar {{ type }} para Selecionados
                                    <v-icon class="ml-2"
                                        >mdi-chevron-right</v-icon
                                    >
                                </v-btn>
                            </div>
                        </template>
                        <span
                            >Selecione um ou mais títulos para liberar o
                            comando</span
                        >
                    </v-tooltip>
                </v-col>
            </v-row>
        </v-card>
        <v-dialog
            v-if="temCerteza"
            v-model="temCerteza"
            max-width="800px"
            persistent
            scrollable
            color="background"
            transition="dialog-bottom-transition"
        >
            <v-card class="confirmation-dialog">
                <v-toolbar flat dark color="primary">
                    <v-btn icon dark @click="closeDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>

                    <v-toolbar-title v-if="titulosEnviadosCheck">
                        Tem certeza?
                    </v-toolbar-title>
                    <v-toolbar-title v-if="resultadoCheck">
                        Envio Finalizado!
                    </v-toolbar-title>
                </v-toolbar>
                <v-divider class="mb-3"></v-divider>

                <div v-if="loadingConfirmacao" class="block-loading">
                    <v-progress-circular
                        :size="50"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                </div>

                <v-card-text
                    v-if="titulosEnviadosCheck"
                    min-height="200px"
                    style="border-radius: 0"
                >
                    <v-row>
                        <v-col>
                            <v-subheader
                                class="primary--text title font-weight-black"
                                >Número de Títulos selecionados:
                                {{ selecionados.length }}
                            </v-subheader>
                        </v-col>
                        <v-col class="d-flex justify-end">
                            <v-btn
                                class="primary white--text d-flex justify-center mt-3"
                                medium
                                v-model="botaoRelatorioXls"
                                v-if="
                                    (isSuperAdminRecuperi &&
                                        type === 'Cancelamento') ||
                                        type === 'Desistência'
                                "
                            >
                                <download-excel
                                    :data="relatorioValores"
                                    :fields="headersCSV"
                                    :name="'Resultado-' + type + '.xls'"
                                    worksheet="resultados"
                                    class="ma-auto"
                                    type="xls"
                                >
                                    <v-icon class="ma-3"
                                        >mdi-microsoft-excel</v-icon
                                    >Relatorio {{ type }}
                                </download-excel>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-subheader
                        >Os seguintes títulos terão
                        {{ type }} Solicitada:</v-subheader
                    >
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th class="text-left">Título</th>
                                <!-- <th class="text-left">Informação</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, i) in selecionados" :key="i">
                                <td>{{ item.ds_nosso_numero }}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card-text>
                <v-card-text
                    v-if="resultadoCheck"
                    class="mx-auto"
                    min-height="200px"
                    style="border-radius: 0px"
                >
                    <v-list class="mx-auto">
                        <v-subheader
                            >Confira abaixo se todos os títulos tiveram sua
                            {{ type }} solicitada:</v-subheader
                        >
                        <div style="max-height: 400px; overflow-y: auto;">
                            <v-data-table
                                scrollable
                                :headers="headers"
                                :items="avisoRetorno"
                                :items-per-page="900"
                                item-key="id"
                                hide-default-footer
                            >
                                <template v-slot:item="{ item }">
                                    <tr>
                                        <td>
                                            <v-icon
                                                v-if="
                                                    item.retorno.codigo === 200
                                                "
                                                class="success--text"
                                            >
                                                mdi-check-circle
                                            </v-icon>
                                            <v-icon
                                                v-else-if="
                                                    item.retorno.codigo === 400
                                                "
                                                class="error--text"
                                            >
                                                mdi-close-circle
                                            </v-icon>
                                        </td>
                                        <td>{{ item.titulo }}</td>
                                        <td>{{ item.id }}</td>
                                        <td>{{ item.retorno.mensagem }}</td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </div>
                        <v-card-text
                            class="ma-auto text-center font-weight-black"
                            >Iniciando download dos resultados...</v-card-text
                        >
                        <v-card-actions class="justify-center">
                            <v-btn
                                class="grey white--text d-flex justify-center"
                                medium
                                v-model="botaoRelatorioXls"
                            >
                                <download-excel
                                    :data="avisoRetorno"
                                    :fields="json_fields"
                                    name="Resultado.xls"
                                    worksheet="resultados"
                                    class="ma-auto"
                                    type="xls"
                                >
                                    <v-icon class="ma-3"
                                        >mdi-microsoft-excel</v-icon
                                    >Baixar XLS Resultados
                                </download-excel>
                            </v-btn>
                        </v-card-actions>
                    </v-list>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions v-if="titulosEnviadosCheck" class="justify-end">
                    <v-btn
                        class="ma-2"
                        color="success"
                        @click="confirmarEnvio"
                        :loading="loadingFiltros"
                    >
                        <v-icon> mdi-chevron-right</v-icon>
                        Seguir com {{ type }}</v-btn
                    ><v-btn class="ma-2" @click="closeDialog">Cancelar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-if="confirmAgrupador"
            v-model="confirmAgrupador"
            max-width="800px"
            persistent
            scrollable
            color="background"
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar flat dark color="primary">
                    <v-btn icon dark @click="closeDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>

                    <v-toolbar-title v-if="titulosEnviadosCheck">
                        Solicitação de Alteração de Agrupador
                    </v-toolbar-title>
                </v-toolbar>
                <v-divider></v-divider>
                <v-card-text
                    min-height="200px"
                    style="border-radius: 0"
                    v-if="titulosEnviadosCheck"
                >
                    <v-list shaped>
                        <v-subheader
                            class="primary--text title font-weight-black"
                            >Número de Títulos selecionados:
                            {{ selecionados.length }}
                        </v-subheader>
                        <v-subheader>
                            Os Títulos abaixo terão a mudança de Agrupador
                            solicitada. Utilize o campo abaixo para definir o
                            novo agrupador a partir da listagem de Agrupadores
                            disponíveis. Importante: ao selecionar "Nenhum
                            Agrupador", todos os Títulos selecionados terão seu
                            agrupador removido.
                        </v-subheader>
                        <v-row class="mx-0 mb-0 mt-3">
                            <v-col cols="4" xs="12" sm="12" md="6" lg="6">
                                <v-select
                                    outlined
                                    dense
                                    color="success"
                                    :disabled="loadingAgrupador"
                                    :loading="loadingAgrupador"
                                    :items="agrupadores"
                                    v-model="novoAgrupador"
                                    label="Novo Agrupador"
                                    required
                                    item-text="nm_agrupador"
                                    item-value="ds_agrupador"
                                    data-cy="novoAgrupador"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row class="ma-0">
                            <v-col
                                cols="4"
                                xs="6"
                                sm="6"
                                md="6"
                                lg="6"
                                class="font-weight-bold"
                            >
                                Título
                            </v-col>
                            <v-col
                                cols="4"
                                xs="6"
                                sm="6"
                                md="6"
                                lg="6"
                                class="font-weight-bold"
                            >
                                Agrupador atual
                            </v-col>
                        </v-row>
                        <v-list-item
                            v-for="(item, i) in selecionados"
                            :key="i"
                            shaped
                            class="px-0"
                        >
                            <v-row class="ma-0">
                                <v-col
                                    cols="4"
                                    xs="6"
                                    sm="6"
                                    md="6"
                                    lg="6"
                                    class="pa-0"
                                >
                                    <v-card-text class="bold py-3">
                                        <v-icon class="ma-1"
                                            >mdi-information</v-icon
                                        >Título:
                                        {{ item.ds_nosso_numero }}
                                    </v-card-text>
                                </v-col>
                                <v-col
                                    cols="4"
                                    xs="6"
                                    sm="6"
                                    md="6"
                                    lg="6"
                                    class="pa-0"
                                >
                                    <v-card-text class="bold py-3">
                                        {{
                                            item.ds_agrupador
                                                ? item.ds_agrupador
                                                : '--'
                                        }}
                                    </v-card-text>
                                </v-col>
                            </v-row>
                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions v-if="titulosEnviadosCheck" class="justify-end">
                    <v-btn
                        class="ma-2"
                        color="success"
                        @click="alteracaoAgrupadorMassa"
                        :loading="loadingFiltros"
                    >
                        <v-icon> mdi-chevron-right</v-icon>
                        Solicitar</v-btn
                    ><v-btn
                        class="ma-2"
                        @click="closeDialog"
                        :loading="loadingFiltros"
                        >Cancelar</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import downloadExcel from 'vue-json-excel';
import TituloService from '../../services/TituloService';
import Vue from 'vue';
export default {
    props: {
        selecionados: {
            type: Array,
            required: true
        },
        avisoRetorno: {
            type: Array,
            required: false
        },
        resultadoCheck: {
            type: Boolean,
            required: false
        },
        loadingFiltros: {
            type: Boolean,
            required: false
        },
        titulosEnviadosCheck: {
            type: Boolean,
            required: false
        },
        type: {
            type: String,
            required: false
        },
        agrupadores: {
            type: Array,
            required: false
        },
        useField: {
            type: String,
            required: false,
            default: 'ds_nosso_numero'
        }
    },
    components: {
        downloadExcel
    },
    data() {
        return {
            tituloService: new TituloService(Vue.http, this.$store),
            novoAgrupador: null,
            temCerteza: false,
            botaoRelatorioXls: null,
            confirmAgrupador: false,
            mostrarBotaoFlutuante: false,
            loadingAgrupador: false,
            headersCSV: {
                'ID Titulo': 'id_titulo',
                'Nosso Número': 'ds_nosso_numero',
                'Status Atual': 'ds_status',
                'Valor Título': 'vl_titulo',
                'Valor Protestado': 'vl_saldo_protesto',
                'Estimativa de Custas': 'vl_custas'
            },

            json_fields: {
                ID: 'id',
                Código: 'retorno.codigo',
                Mensagem: 'retorno.mensagem'
            },
            relatorioValores: [],
            headers: [
                {
                    text: 'Status',
                    align: 'start',
                    value: 'retorno.codigo'
                },
                { text: 'Título', value: 'titulo' },
                { text: 'ID', value: 'id' },
                { text: 'Mensagem', value: 'mensagem' }
            ],
            loadingConfirmacao: false
        };
    },
    computed: {
        isSuperAdminRecuperi() {
            return this.$store.getters.isSuperAdminRecuperi;
        }
    },
    methods: {
        alteracaoAgrupadorMassa() {
            let data = this.selecionados.map(e => e.ds_nosso_numero);
            this.$emit('enviar', data, this.novoAgrupador);
        },
        onIntersect(entries) {
            const isIntersecting = entries[0].isIntersecting;
            this.mostrarBotaoFlutuante = !isIntersecting;
        },
        dialogTemCerteza() {
            if (this.type === 'Agrupador') {
                this.confirmAgrupador = true;
            } else {
                this.temCerteza = true;
                this.getRelatorio();
            }
        },
        getRelatorio() {
            let data = this.selecionados.map(e => e.id_titulo);
            this.loadingConfirmacao = true;
            this.tituloService
                .getRelatorioValoresCancelamentoDesistencia(
                    this.$store.getters.cdApresentante,
                    data
                )
                .then(response => {
                    this.relatorioValores = response.data.titulos;
                    this.loadingConfirmacao = false;
                });
        },
        fecharDialog() {
            this.temCerteza = false;
            this.confirmAgrupador = false;
        },
        closeDialog() {
            this.temCerteza = false;
            this.$emit('clearAll', true);
            // window.location.reload();
        },
        confirmarEnvio() {
            let data = this.selecionados.map(e => e[this.useField]);
            this.$emit('enviar', data);
        }
    }
};
</script>

<style scoped lang="scss">
.confirmation-dialog {
    header {
        position: relative;
        z-index: 2;
    }

    .block-loading {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
